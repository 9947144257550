import { MonitoramentoPedidosRepository } from "../../../repositories/defaultUserRepositories/MonitoramentoPedidosRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async findMonitoramentoPedidos({ commit, state, dispatch }) {
    commit("setIsLoading", true);
    commit("setListingMonPedidos", []);
    try {
      const response =
        await MonitoramentoPedidosRepository.requestMonitoramentoPedidos(
          state.urlParams
        );

      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit("setListingMonPedidos", response.data.data.list);
        commit("setMonPedidosPagination", response.data.data.pagination);
        commit("setIsLoading", false);
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
  haveFilters({ commit }, value) {
    commit("setHaveFilters", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setPedidosReenvio({ commit }, value) {
    commit("setPedidosReenvio", value);
  },
  setReenvioDialog({ commit }, value) {
    commit("setReenvioDialog", value);
  },
  async generateOrdersReport({ commit }, { body, fileName, fileFormat }) {
    try {
      const response = await MonitoramentoPedidosRepository.generateOrdersReport(
        body,
        fileName,
        fileFormat
      );
      if (response?.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Relatório gerado com sucesso",
          },
          { root: true }
        );
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response?.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
};
