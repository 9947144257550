export default {
  setPedidosPerType(state, response) {
    state.pedidosPerType = response;
  },

  setPedidosPerWeek(state, response) {
    state.pedidosPerWeek = response;
  },

  setColetasPerWeek(state, response) {
    state.coletasPerWeek = response;
  },

  setPlacasPerWeek(state, response) {
    state.placasPerWeek = response;
  },
  setAwaitingEntry(state, response) {
    state.awaitingEntry = response;
  },
  setShippingByCarrier(state, response) {
    state.shippingByCarrier = response;
  },
  setLateCollections(state, response) {
    state.lateCollections = response;
  },

  setCollectsAndPanelsByDispatcher(state, response) {
    state.collectsAndPanelsByDispatcher = response;
  },

  setOrdersPerShippingCompany(state, response) {
    state.ordersPerShippingCompany = response;
  },

  setStatusOfTrucksOnArrival(state, response) {
    state.statusOfTrucksOnArrival = response;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setInitialDate(state, response) {
    state.initialDate = response;
  },

  setFinalDate(state, response) {
    state.finalDate = response;
  },
};
