export default {
  listingBar: [],
  pedidosPerWeek: [],
  pedidosPerType: [],
  coletasPerWeek: [],
  placasPerWeek: [],
  collectsAndPanelsByDispatcher: [],
  ordersPerShippingCompany: [],
  statusOfTrucksOnArrival: [],
  awaitingEntry: [],
  shippingByCarrier: [],
  lateCollections: [],
  isLoading: false,
  initialDate: "",
  finalDate: "",
};
