<template>
  <div>
    <v-container v-if="loadingInit" class="mt-8">
      <div>
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
    </v-container>
    <v-container v-else>
      <cabecalho
        @atualizar-lista="reloadColetaDetalhes"
        @coletar-assinatura="openColetarAssinaturaMotoristaDialog"
        @finalizar-coleta="openConfirmaFinalizarColetaDialog"
        @checar-volume-com-erro="checkVolumeBipadoPelaCameraComErro"
        @getFilter="getFilter"
        @onIntersecting="onIntersecting"
        :volumesRef="volumesRef"
        @emitRef="getPedidosRef"
      />
      <tabela-volumes
        @atualizar-lista="reloadColetaDetalhes"
        @bipagem="reloadColetaDetalhes"
        @open-volume-detalhes-dialog="openDetalhesVolumeDialog($event)"
        :volumes="filteredVolumes"
        :loadingReloadColetaDetalhes="loadingReloadColetaDetalhes"
        :key="tabelaVolumesKey"
        @emitRef="getVolumesRef"
      />
      <!--MODAIS-->
      <volume-detalhes
        @close="dialogDetalhesVolume.enabled = false"
        :key="dialogDetalhesVolumeKey"
        :enabled="dialogDetalhesVolume.enabled"
        :volume="dialogDetalhesVolume.volume"
      />
      <volumes-bipados-pela-camera />
      <recolher-assinatura-motorista
        @close="dialogRecolherAssinatura.enabled = false"
        @atualiza-assinatura="reloadColetaDetalhes"
        :key="dialogRecolherAssinaturaKey"
        :enabled="dialogRecolherAssinatura.enabled"
      />
      <confirma-finalizar-coleta
        @close="dialogConfirmaFinalizarColeta.enabled = false"
        @confirma-finalizar="confirmaFinalizarColeta"
        :key="dialogConfirmaFinalizarColetaKey"
        :enabled="dialogConfirmaFinalizarColeta.enabled"
      />
      <modal-error-pedido
        @close="dialogModelError.enabled = false"
        :enabled="dialogModelError.enabled"
      />
      <v-btn
        v-show="!isIntersecting"
        @click="$vuetify.goTo(pedidosRef)"
        fab
        small
        color="navy"
        fixed
        bottom
        right
        ><v-icon color="base">mdi-chevron-up</v-icon></v-btn
      >
      <v-snackbar color="blue" tile v-model="snackbar" timeout="1000"
        ><span class="text-uppercase d-block text-center"
          >Não há volumes neste pedido</span
        ></v-snackbar
      >
    </v-container>
    <alert-modal
      v-model="isAlertModalOpen"
      :key="alertModalKey"
      :orders="getColetaDetalhes.pedidosNaoFaturados"
    />
  </div>
</template>

<script>
import EmbarqueColetaCabecalho from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaCabecalho";
import EmbarqueColetaTabelaVolumes from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaTabelaVolumes";
import EmbarqueColetaVolumeDetalhes from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaVolumeDetalhes";
import EmbarqueColetaVolumesBipadosPelaCamera from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaVolumesBipadosPelaCamera";
import EmbarqueColetaRecolherAssinaturaMotorista from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaRecolherAssinaturaMotorista";
import EmbarqueColetaDialogConfirmaFinalizarColeta from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaDialogConfirmaFinalizarColeta";
import { mapGetters, mapActions, mapState } from "vuex";
import { coletasSocketService } from "@/services/socket/coletasSocketService";
import EmbarqueColetaModelError from "@/components/defaultUserComponents/embarqueColeta/EmbarqueColetaModelError.vue";
import AlertModal from "../../components/defaultUserComponents/embarqueColeta/AlertModal.vue";

export default {
  name: "EmbarqueColeta",
  components: {
    RecolherAssinaturaMotorista: EmbarqueColetaRecolherAssinaturaMotorista,
    ConfirmaFinalizarColeta: EmbarqueColetaDialogConfirmaFinalizarColeta,
    VolumesBipadosPelaCamera: EmbarqueColetaVolumesBipadosPelaCamera,
    VolumeDetalhes: EmbarqueColetaVolumeDetalhes,
    TabelaVolumes: EmbarqueColetaTabelaVolumes,
    Cabecalho: EmbarqueColetaCabecalho,
    ModalErrorPedido: EmbarqueColetaModelError,
    AlertModal,
  },
  mounted() {
    Promise.all([
      this.loadColetaDetalhes(),
      this.connectWithColetasWebSocket(),
      this.volumesComErros(~~this.$route.params.coletaId),
      this.checkVolumeBipadoPelaCameraComErro(),
    ]);
  },
  data: () => ({
    isIntersecting: true,
    pedido: "",
    filter: "",
    volumesRef: "",
    pedidosRef: "",
    snackbar: false,
    tabelaVolumesKey: 0,
    loadingInit: false,
    loadingReloadColetaDetalhes: false,
    dialogDetalhesVolume: { enabled: false, volume: {} },
    dialogDetalhesVolumeKey: "A",
    dialogRecolherAssinatura: { enabled: false, volume: {}, key: 0 },
    dialogRecolherAssinaturaKey: "B",
    dialogConfirmaFinalizarColeta: { enabled: false, volume: {}, key: 0 },
    dialogConfirmaFinalizarColetaKey: "C",
    // dialogModelError: { enabled: false },
    coletasSocketService,
    isAlertModalOpen: false,
    alertModalKey: 0,
  }),
  computed: {
    ...mapGetters("expedition", [
      "getColetaDetalhes",
      "getVolumesComErros",
      "getModalVolumesComErros",
    ]),
    filteredVolumes() {
      if (this.filter.type === "pedidos") {
        return this.getColetaDetalhes.volumes.filter(
          (volume) => volume.numeroPedido === this.filter.number
        );
      }
      return this.getColetaDetalhes.volumes.filter(
        (volume) => volume.cargaId === this.filter.number
      );
    },
    getColetaId() {
      return ~~this.$route.params.coletaId;
    },
    dialogModelError() {
      return {
        enabled: this.getVolumesComErros.length > 0,
      };
    },
  },
  destroyed() {
    this.coletasSocketService.disconnect();
  },
  methods: {
    ...mapActions("expedition", [
      "findColetaDetalhesByColetaId",
      "reloadColetaDetalhesByColetaId",
      "finalizaColeta",
      "volumesComErros",
    ]),
    getPedidosRef(pedidosRef) {
      this.pedidosRef = pedidosRef;
    },
    getVolumesRef(volumesRef) {
      this.volumesRef = volumesRef;
    },
    onIntersecting(isIntersecting) {
      this.isIntersecting = isIntersecting;
    },
    getFilter({ filter }) {
      this.filter = filter;
    },
    connectWithColetasWebSocket() {
      const coletaId = this.getColetaId;
      this.coletasSocketService.connect({ coletaId }).onColetaUpdate(() => {
        this.reloadColetaDetalhes();
      });
    },
    async loadColetaDetalhes() {
      this.loadingInit = true;
      await this.findColetaDetalhesByColetaId(this.getColetaId);
      this.loadingInit = false;
    },
    async reloadColetaDetalhes() {
      this.tabelaVolumesKey = this.tabelaVolumesKey + 1;
      this.loadingReloadColetaDetalhes = true;
      await this.reloadColetaDetalhesByColetaId(this.getColetaId).then(() => {
        this.volumesComErros(this.getColetaId);
        this.loadingReloadColetaDetalhes = false;
      });
    },
    async confirmaFinalizarColeta() {
      let response = await this.finalizaColeta(this.getColetaId);
      if (response.status >= 200 && response.status < 300) {
        this.$router.replace({ name: "Coletas Agendadas" });
      }
    },
    openDetalhesVolumeDialog({ volumeId }) {
      this.dialogDetalhesVolumeKey = this.dialogDetalhesVolumeKey + 1;
      this.dialogDetalhesVolume.enabled = true;
      this.dialogDetalhesVolume.volume = { volumeId };
    },
    openColetarAssinaturaMotoristaDialog() {
      this.dialogRecolherAssinaturaKey = this.dialogRecolherAssinaturaKey + 1;
      this.dialogRecolherAssinatura.enabled = true;
    },
    openConfirmaFinalizarColetaDialog() {
      this.dialogConfirmaFinalizarColetaKey =
        this.dialogConfirmaFinalizarColetaKey + 1;
      this.dialogConfirmaFinalizarColeta.enabled = true;
    },
    openModelErrorDialog() {
      // this.dialogModelError.enabled = this.getModalVolumesComErros;
    },
    async checkVolumeBipadoPelaCameraComErro() {
      await this.volumesComErros(this.getColetaId);
      // if (this.getVolumesComErros.length > 0) {
      // this.openModelErrorDialog();
      // }
    },
  },
  watch: {
    filter() {
      if (this.filter.number && this.filteredVolumes.length === 0) {
        this.snackbar = true;
      }
    },
    getColetaDetalhes() {
      // this.isAlertModalOpen = this.getColetaDetalhes.existePedidoNaoFaturado;
      this.alertModalKey++;
    },
  },
};
</script>

<style scoped></style>
