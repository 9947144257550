<template>
  <div>
    <default-table-head class="d-none d-md-flex">
      <v-col cols="3" class="border-right">
        <span>Transportadora</span>
      </v-col>
      <v-col cols="2" class="border-right">
        <span>Código Winthor</span>
      </v-col>
      <v-col cols="3" class="border-right">
        <span>CNPJ</span>
      </v-col>
      <v-col cols="1" class="border-right">
        <span title="Pedidos" class="text-truncate d-block">Pedidos</span>
      </v-col>
    </default-table-head>
    <template v-if="!isLoading">
      <default-table-body-row
        justify="center"
        v-for="(transportadora, index) in getFilteredTransportadoras"
        :key="index"
      >
        <v-col cols="6" md="3">
          <span class="d-md-none font-weight-bold">Transportadora</span>
          <span
            class="d-block text--text text-truncate"
            :title="transportadora.nomeFantasia"
            >{{ transportadora.nomeFantasia }}</span
          >
        </v-col>
        <v-col cols="6" md="2">
          <span class="d-md-none font-weight-bold">Código Winthor</span>
          <span class="d-block text-wrap secondary--text font-weight-bold">
            {{ transportadora.winthorCodigo }}
          </span>
        </v-col>

        <v-col cols="6" md="3">
          <span class="d-md-none font-weight-bold">CNPJ</span>
          <span :title="transportadora.cnpj" class="d-block text-truncate">
            {{ transportadora.cnpj }}
          </span>
        </v-col>
        <v-col cols="6" md="1">
          <span class="d-md-none font-weight-bold">Pedidos</span>
          <span class="d-block text-wrap secondary--text font-weight-bold">
            {{ transportadora.pedidosSemColeta.length }}
          </span>
        </v-col>

        <v-col cols="6" md="1">
          <default-fab
            tooltip-text="Editar E-mails"
            @click="openUpdateTransportadoraDialog(transportadora)"
          >
            <v-icon>mdi-email-edit</v-icon>
          </default-fab>
        </v-col>
        <v-col cols="6" md="2">
          <default-button @click="sendRemessa(transportadora)">
            <span>Remessas</span>
          </default-button>
        </v-col>
      </default-table-body-row>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <v-dialog
      v-model="dialogUpdateTransportadora"
      scrollable
      max-width="600px"
      transition="dialog-top-transition"
    >
      <edit-email
        :key="reRender"
        :transportadora="transportadoraObj"
        @transportadoras="transportadoras"
        @closeTransportadoraDialog="closeTransportadoraDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DefaultButton from "../../defaultComponents/DefaultButton.vue";
import DefaultFab from "../../defaultComponents/DefaultFab.vue";
import DefaultSpinner from "../../defaultComponents/DefaultSpinner.vue";
import DefaultTableBodyRow from "../../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../../defaultComponents/DefaultTableHead.vue";
import EditEmail from "./EditEmail.vue";

export default {
  components: {
    DefaultButton,
    DefaultFab,
    DefaultSpinner,
    DefaultTableBodyRow,
    DefaultTableHead,
    EditEmail,
  },
  name: "TransportadorasList",
  data: () => ({
    dialog: false,
    reRender: 0,
    transportadoraObj: {},
    dialogUpdateTransportadora: false,
  }),
  mounted() {
    this.transportadoras();
  },
  computed: {
    ...mapGetters("transportadora", [
      "getListingTransportadoras",
      "getFilteredTransportadoras",
    ]),
    ...mapState("transportadora", ["isLoading"]),
  },
  methods: {
    ...mapActions("transportadora", ["transportadoras"]),
    sendRemessa(transportadora) {
      const { transportadoraId, cnpj, nomeFantasia } = transportadora;
      this.$router.push({
        name: "Remessas da Transportadora",
        params: { transportadoraId, cnpj, nomeFantasia },
      });
    },
    openUpdateTransportadoraDialog(transportadora) {
      this.transportadoraObj = transportadora;
      this.reRender += 1;
      this.dialogUpdateTransportadora = true;
    },
    closeTransportadoraDialog() {
      this.dialogUpdateTransportadora = false;
    },
  },
};
</script>

<style scoped></style>
