export default {
  isLoading: false,
  listingPallets: [],
  listingPalletsAmount:[],
  listingPaineis: [],
  paineisPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    search: "",
    painelId: "",
  },
};
