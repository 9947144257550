import { HttpRestService } from "@/services/http/HttpRestService";
import { HttpFileRequestService } from "@/services/http/HttpFileRequestService";

export class MonitoramentoPedidosRepository {
  static requestMonitoramentoPedidos(urlParams) {
    return HttpRestService.get(`/pedido/monitoramento/intelipost`, urlParams);
  }
  static generateOrdersReport(body, fileName, fileFormat) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(`/pedido/monitoramento/intelipost/relatorio`, body)
      .setBody(body)
      .request(fileFormat, fileName);
  }
}
