import { HttpRestService } from "@/services/http/HttpRestService";

export class DashboardRepository {
  static requestPedidosPerType() {
    return HttpRestService.get(`/dashboard/tipo/pedidos`);
  }

  static requestPedidosPerWeek() {
    return HttpRestService.get("/dashboard/pedidos");
  }

  static requestColetasPerWeek() {
    return HttpRestService.get("/dashboard/coletas");
  }

  static requestPlacasPerWeek() {
    return HttpRestService.get("/dashboard/placas");
  }

  static requestCollectsAndPanelsByDispatcher() {
    return HttpRestService.get(
      "/dashboard/coletas/paineis/expedidos/despachante"
    );
  }

  static requestOrdersPerShippingCompany() {
    return HttpRestService.get("/dashboard/pedidos/embarcados/transportadoras");
  }

  static requestStatusOfTrucksOnArrival() {
    return HttpRestService.get("/dashboard/entrada/status");
  }

  static awaitingEntry() {
    return HttpRestService.get("/transportadora/aguardando/entrada");
  }
  static shippingByCarrier() {
    return HttpRestService.get(
      "/dashboard/pedidos/despachados/transportadoras"
    );
  }
  static lateCollections() {
    return HttpRestService.get("/dashboard/coletas/atrasadas");
  }
}
