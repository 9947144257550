export default {
  getPedidosPerType(state) {
    return state.pedidosPerType;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getInitialDate(state) {
    return state.initialDate;
  },
  
  getFinalDate(state) {
    return state.finalDate;
  },

  getPedidosPerWeek: (state) => state.pedidosPerWeek,

  getPedidosPerWeek: (state) => state.pedidosPerWeek,

  getColetasPerWeek: (state) => state.coletasPerWeek,

  getPlacasPerWeek: (state) => state.placasPerWeek,

  getCollectsAndPanelsByDispatcher: (state) =>
    state.collectsAndPanelsByDispatcher,

  getOrdersPerShippingCompany: (state) => state.ordersPerShippingCompany,

  getStatusOfTrucksOnArrival: (state) => state.statusOfTrucksOnArrival,

  getAwaitingEntry: (state) => state.awaitingEntry,

  getShippingByCarrier: (state) => state.shippingByCarrier,

  getLateCollections: (state) => state.lateCollections,
};
