import { DashboardRepository } from "../../../repositories/defaultUserRepositories/DashboardRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async requestPedidosPerType({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.requestPedidosPerType();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setPedidosPerType", response.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestPedidosPerWeek({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.requestPedidosPerWeek();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setPedidosPerWeek", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestColetasPerWeek({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.requestColetasPerWeek();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setColetasPerWeek", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestPlacasPerWeek({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.requestPlacasPerWeek();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setPlacasPerWeek", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async awaitingEntry({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.awaitingEntry();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setAwaitingEntry", response.data.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestCollectsAndPanelsByDispatcher({ commit, dispatch }) {
    try {
      const response =
        await DashboardRepository.requestCollectsAndPanelsByDispatcher();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setCollectsAndPanelsByDispatcher", response.data.data);
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async shippingByCarrier({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.shippingByCarrier();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setShippingByCarrier", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestOrdersPerShippingCompany({ commit, dispatch }) {
    try {
      const response =
        await DashboardRepository.requestOrdersPerShippingCompany();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setOrdersPerShippingCompany", response.data.data);
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async lateCollections({ commit, dispatch }) {
    try {
      const response = await DashboardRepository.lateCollections();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setLateCollections", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async requestStatusOfTrucksOnArrival({ commit, dispatch }) {
    try {
      const response =
        await DashboardRepository.requestStatusOfTrucksOnArrival();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setStatusOfTrucksOnArrival", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setInitialDate({ commit }, value) {
    commit("setInitialDate", value);
  },

  setFinalDate({ commit }, value) {
    commit("setFinalDate", value);
  },
  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
