<template>
  <default-modal
    hide-close-button
    persistent
    v-model="isAlertModalOpen"
    width="550"
  >
    <v-card-title class="justify-center mb-n4"
      ><v-icon size="70" color="pink"
        >mdi-alert-circle-outline</v-icon
      ></v-card-title
    >
    <v-card-title
      class="justify-center font-weight-bold text-uppercase mb-n3"
      >{{
        orders.length > 1
          ? "OS PEDIDOS ABAIXO NÃO TÊM NOTA FATURADA"
          : "O PEDIDO ABAIXO NÃO TEM NOTA FATURADA"
      }}</v-card-title
    >
    <span>Edite a coleta para desbloqueá-la</span>
    <v-card-title>
      <v-row class="mt-3 mb-7" style="gap: 5px" justify="center">
        <v-col
          v-for="(order, index) in orders"
          :key="index"
          cols="3"
          class="primary font-weight-bold white--text pa-1 rounded"
          >{{ order.numeroPedido }}</v-col
        >
      </v-row>
    </v-card-title>
    <v-card-actions class="justify-center">
      <v-btn color="pink" dark @click="isAlertModalOpen = false">Fechar</v-btn>
    </v-card-actions>
  </default-modal>
</template>

<script>
import DefaultModal from "../../defaultComponents/DefaultModal.vue";

export default {
  name: "AlertModal",
  components: {
    DefaultModal,
  },
  props: {
    value: {},
    orders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    isAlertModalOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
